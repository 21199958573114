import settings from '../customer-settings/settings';
import { ETranslation } from './translation-keys';

const appTranslations = {
  [ETranslation.COMMON_CLOSE]: "Закрыть",
  [ETranslation.COMMON_SEND]: "Отправить",
};

const authTranslations = {
  [ETranslation.AUTH_ENTER_PHONENUMBER]: "Укажите Ваш номер телефона",
  [ETranslation.AUTH_PHONENUMBER_TEXT]: "Укажите Ваш номер телефона в следующем формате: +358401234567. Без пробелов и знаков препинания. ",
  [ETranslation.AUTH_CONTINUE]: "Продолжить",
  [ETranslation.AUTH_LOGIN_ID_ERROR_SENDING]: "Не удалось отправить проверочный код. Проверьте указанный Вами номер телефона и попробуйте еще раз.",
  [ETranslation.AUTH_LOGIN_ID_ERROR]: "Неверный код. Попробуйте еще раз ввести свой проверочный код.",
  [ETranslation.AUTH_LOGIN_ID_GIVE]: "Введите код подтверждения",
  [ETranslation.AUTH_LOGIN_ID_GET]: "Вы получите смс-сообщение с кодом в течение 30 секунд."
};

const contactRequestTranslations = {
  [ETranslation.CONTACT_REQUEST_SUBJECT]: "Тема",
  [ETranslation.CONTACT_REQUEST_THANK_YOU]: "Спасибо, что связались с нами. Мы свяжемся с Вами в ближайшее время. ",
  [ETranslation.CONTACT_REQUEST_MUNICIPALITY]: "Муниципалитет проживания",
  [ETranslation.CONTACT_REQUEST_CHOOSE_MUNICIPILATY]: "Выберите Ваш муниципалитет проживания",
  [ETranslation.CONTACT_REQUEST_CHOOSE_SUBJECT]: "Выберите тему",
  [ETranslation.CONTACT_REQUEST_INPUT_SUBJECT]: "Введите тему",
  [ETranslation.CONTACT_REQUEST_OTHER_SUBJECT]: "Другое",
  [ETranslation.CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE]: "Язык перевода",
  [ETranslation.CONTACT_REQUEST_I_NEED_INTERPRETER]: "Мне нужен переводчик",
  [ETranslation.CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE]: "Выберите язык перевода",
  [ETranslation.CONTACT_REQUEST_CONTACT_US_EMAIL]: "Вы можете связаться с нами также по эл. почте:",
};

const infoListTranslations = {
  [ETranslation.INFO_LIST_CONTACT_REQUEST]: "Оставьте заявку и мы с Вами свяжемся.",
  [ETranslation.INFO_LIST_OPEN_CONTACT_REQUEST]: "ОТКРЫТЫЙ ЗАПРОС",
  [ETranslation.INFO_LIST_IDENTIFICATION]: "Пройдите идентификацию",
};

const modalTranslations = {
  [ETranslation.MODAL_CONTACT_REQUEST]: "Оставьте заявку и мы с Вами свяжемся.",
  [ETranslation.MODAL_LANGUAGE]: `Мобильное приложение ${settings.name}`
};

const contactRequestDataTranslations = {
  [ETranslation.DATA_IMMIGRATION]: "Вопросы по иммиграции",
  [ETranslation.DATA_PERMIT]: "Вопросы по разрешению на пребывание (Миграционная служба)",
  [ETranslation.DATA_DECISIONS]: "Вопросы по официальным решениям и процессам рассмотрения заявок. Заполнение бланков.",
  [ETranslation.DATA_STUDYING]: "Учеба",
  [ETranslation.DATA_WORK]: "Работа и поиск вакансий",
  [ETranslation.DATA_OTHER]: "Другое. Укажите тему.",
  [ETranslation.DATA_NEW]: "Новый запрос",
  [ETranslation.DATA_IN_PROGRESS]: "В процессе",
  [ETranslation.DATA_COMPLETE]: "Готово"

};

const languageModalTranslations = {
  [ETranslation.LANGUAGE_MODAL_CHOOSE_LANGUAGE]: "Выберите язык"
};

const openContactRequestModalTranslations = {
  [ETranslation.OPEN_CONTACT_MODAL_ARE_YOU_SURE]: "Вы действительно хотите отменить запрос?",
  [ETranslation.OPEN_CONTACT_MODAL_YES]: "Да",
  [ETranslation.OPEN_CONTACT_MODAL_NO]: "Нет",
  [ETranslation.OPEN_CONTACT_MODAL_SUBJECT]: "Вы оставили запрос по следующей теме:",
  [ETranslation.OPEN_CONTACT_MODAL_RESPOND]: "Мы ответим Вам в ближайшее время.",
  [ETranslation.OPEN_CONTACT_MODAL_CLOSE]: "Закрыть",
  [ETranslation.OPEN_CONTACT_MODAL_CANCEL]: "Отменить",
};

const infoTranslations = {
  [ETranslation.INFO_CLOSE_CAPS]: "ЗАКРЫТЬ"
};

const customFetchTranslations = {
  [ETranslation.ERROR_NO_AUTH]: "Ошибка! Пользователь не найден или нет прав доступа."
};

const translationsRu = {
  ...appTranslations,
  ...authTranslations,
  ...contactRequestTranslations,
  ...infoListTranslations,
  ...modalTranslations,
  ...contactRequestDataTranslations,
  ...languageModalTranslations,
  ...openContactRequestModalTranslations,
  ...infoTranslations,
  ...customFetchTranslations,
};

export default translationsRu;
