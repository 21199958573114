import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

import classes from './IconInput.module.scss';


interface IProps {
  label?: string;
  labelTranslation?: ETranslation;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  icon: IconDefinition;
  iconChecked: IconDefinition;
  className?: string;
  classNameChecked?: string;
}

const IconInput: React.FC<IProps> = ({
  label,
  labelTranslation,
  onChange,
  checked,
  icon,
  iconChecked,
  className,
  classNameChecked
}) => {

  const { t } = useTranslation();
  const iconClassNames = [classes.Icon];

  if (className) iconClassNames.push(className);

  if(checked) {
    iconClassNames.push(classes.Checked);
    if (classNameChecked) iconClassNames.push(classNameChecked);
  }

  return (
    <div className={classes.Container}>
      {labelTranslation && <label className={[classes.Label, "no-select"].join(' ')}>{labelTranslation ? t(labelTranslation) : label}</label>}
      <span className={iconClassNames.join(' ')} onClick={() => onChange(value => !value)}>
        <FontAwesomeIcon icon={checked ? iconChecked : icon}/>
      </span>
    </div>
  );
};

export default IconInput;
