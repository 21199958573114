let hostname;
let port: string = '';
if(window && window.location) {
  hostname = window.location.hostname;
  port = window.location.port;
}
const isDevelopment = hostname && (hostname === 'localhost' || hostname.startsWith('192.168'));

export enum ECustomer {
  maspa = 'maspa',
  alipi = 'alipi'
}

let serverUrl = '';
let apiUrl = '';
if (isDevelopment) {
  serverUrl = `http://${hostname}:8080`;
  apiUrl = `http://${hostname}:8080/json`
} else {
  serverUrl = 'https://youpret-info.appspot.com/';
  apiUrl = 'https://www.youpret.com/json';
}


let baseFolder = '/app';
if(isDevelopment && port === '3001') {
  baseFolder = '';
} 



export { isDevelopment, serverUrl, baseFolder, apiUrl };