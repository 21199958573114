import { useEffect } from 'react';


export const useHideOverflow = (hide: boolean) => {
  useEffect(() => {
    if(hide) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.removeProperty('overflow-y');
    }
    return () => {
      document.body.style.removeProperty('overflow-y');
    }
  }, [hide]);
}