import { ETranslation } from './../../../translations/translation-keys';
import React, { ReactNode } from "react";


export interface IModal {
  content: ReactNode;
  isOpen: boolean;
  title?: string;
  titleTranslation?: ETranslation;
  loading?: boolean;
  disabled?: boolean;
  onModalClose?: () => void;
  noScroll?: boolean;
}

export const defaultModal: IModal = {
  content: null,
  isOpen: false,
  title: "",
  loading: false,
  disabled: false,
  onModalClose: () => {},
};

export type TSetModal = (modal: React.SetStateAction<IModal>) => void;

const ModalContext = React.createContext<{
  setModal: TSetModal;
  closeModal: () => void;
}>({
  setModal: (modal: React.SetStateAction<IModal>) => {},
  closeModal: () => {},
});

export default ModalContext;
