import { IUser } from './../interfaces/IUser';
import { customFetch, EFetchMethod } from './../custom-fetch';


export const getUser = async () => {
  return await customFetch<IUser>(`/infousers/get`);
}


export const saveUser = async (user: Partial<IUser>) => {
  return await customFetch<IUser>(`/infousers/add`, EFetchMethod.POST, JSON.stringify(user));
}

export const updateUser = async (user: Partial<IUser>) => {
  return await customFetch<IUser>(`/infousers/update`, EFetchMethod.PUT, JSON.stringify(user));
}