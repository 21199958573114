import { ETranslation } from './translations/translation-keys';
import { getToken } from './local-storage';
import { apiUrl } from "./config";

export enum EFetchMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT"
}

type TBody =
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null
  | undefined;

export const customFetch = function<T>(
  url: string,
  method: EFetchMethod = EFetchMethod.GET,
  body?: TBody,
  signal?: AbortSignal
): Promise<T> {
  return fetch(`${apiUrl}${url}`, {
    method,
    body,
    credentials: "include",
    headers: {
      'X-Client': 'Info-React',
      "Authorization": "Bearer " + getToken()
    },
    signal
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then(error => {
        if(error === 'NO_AUTH') error = ETranslation.ERROR_NO_AUTH
        throw new Error(error);
      });
    })
    .catch(error => {
      if(error instanceof Error) {
        throw error;
      } else {
        throw new Error(error);
      }
    });
};


