
export enum EContactRequestSubject {
  IMMIGRATION = 'IMMIGRATION',
  PERMIT_MIGRI = 'PERMIT_MIGRI',
  CLARIFICATION_AND_FORMS = 'CLARIFICATION_AND_FORMS',
  STUDYING = 'STUDYING',
  WORK = 'WORK',
  OTHER = 'OTHER',
}

export enum EContactRequestStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface IContactRequest {
  id: string;
  subject: EContactRequestSubject;
  subjectOther?: string;
  status: EContactRequestStatus;
  urgent: boolean;
  municipality: string;
  needInterpreter: boolean;
  toLanguage: string;
}