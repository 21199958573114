import './index.scss';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { isDevelopment, serverUrl } from './config';
import IAppState from './interfaces/store/IAppState';
import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';

import infoReducer from './store/reducers/infoReducer'
import authReducer from './store/reducers/authReducer'
import contactRequestReducer from './store/reducers/contactRequestReducer';
import languageReducer from './store/reducers/languageReducer';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import initilizeFirebase from './firebase';

initilizeFirebase();

if(!isDevelopment && window.location.protocol !== 'https:') {
  window.location.href = serverUrl;
}


const rootReducer: Reducer<IAppState> = combineReducers({
  info: infoReducer,
  auth: authReducer,
  contactRequest: contactRequestReducer,
  language: languageReducer
  
});

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);