import settings from '../customer-settings/settings';
import { ETranslation } from './translation-keys';

const appTranslations = {
  [ETranslation.COMMON_CLOSE]: "Stäng",
  [ETranslation.COMMON_SEND]: "Skicka",
};

const authTranslations = {
  [ETranslation.AUTH_ENTER_PHONENUMBER]: "Ange ditt telefonnummer",
  [ETranslation.AUTH_PHONENUMBER_TEXT]: "Ange ditt mobiltelefonnummer i formen +358401234567 utan skiljetecken och mellanslag.",
  [ETranslation.AUTH_CONTINUE]: "Fortsätt",
  [ETranslation.AUTH_LOGIN_ID_ERROR_SENDING]: "Sändning av verifieringskod misslyckades, kontrollera telefonnumret och försök igen.",
  [ETranslation.AUTH_LOGIN_ID_ERROR]: "En oriktig verifieringskod, försök igen.",
  [ETranslation.AUTH_LOGIN_ID_GIVE]: "Ange din verifieringskod",
  [ETranslation.AUTH_LOGIN_ID_GET]: "Du får en verifieringskod som textmeddelande inom 30 sekunder."
};

const contactRequestTranslations = {
  [ETranslation.CONTACT_REQUEST_SUBJECT]: "Ämne",
  [ETranslation.CONTACT_REQUEST_THANK_YOU]: "Tack för att du kontaktade oss! Vi svarar dig så snabbt som möjligt.",
  [ETranslation.CONTACT_REQUEST_MUNICIPALITY]: "Boendekommun",
  [ETranslation.CONTACT_REQUEST_CHOOSE_MUNICIPILATY]: "Välj din boendekommun",
  [ETranslation.CONTACT_REQUEST_CHOOSE_SUBJECT]: "Välj ämne",
  [ETranslation.CONTACT_REQUEST_INPUT_SUBJECT]: "Ange ämne",
  [ETranslation.CONTACT_REQUEST_OTHER_SUBJECT]: "Annat ämne",
  [ETranslation.CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE]: "Tolkningsspråk",
  [ETranslation.CONTACT_REQUEST_I_NEED_INTERPRETER]: "Jag behöver en tolk",
  [ETranslation.CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE]: "Välj ditt tolkningsspråk",
  [ETranslation.CONTACT_REQUEST_CONTACT_US_EMAIL]: "Du kan också kontakta oss via e-post:",
};

const infoListTranslations = {
  [ETranslation.INFO_LIST_CONTACT_REQUEST]: "Lämna kontaktbegäran ",
  [ETranslation.INFO_LIST_OPEN_CONTACT_REQUEST]: "ÖPPEN KONTAKTBEGÄRAN",
  [ETranslation.INFO_LIST_IDENTIFICATION]: "Identifiera dig",
};
const modalTranslations = {
  [ETranslation.MODAL_CONTACT_REQUEST]: "Lämna kontaktbegäran ",
  [ETranslation.MODAL_LANGUAGE]: `${settings.name}-mobilapplikation`
};

const contactRequestDataTranslations = {
  [ETranslation.DATA_IMMIGRATION]: "Invandringsärenden",
  [ETranslation.DATA_PERMIT]: "Tillståndsärenden (Migrationsverket, Migri)",
  [ETranslation.DATA_DECISIONS]: "Klargörande av beslut och processer, blanketter",
  [ETranslation.DATA_STUDYING]: "Studier",
  [ETranslation.DATA_WORK]: "Arbete och arbetssökning",
  [ETranslation.DATA_OTHER]: "Annat, vad?",
  [ETranslation.DATA_NEW]: "Ny",
  [ETranslation.DATA_IN_PROGRESS]: "Pågående",
  [ETranslation.DATA_COMPLETE]: "Färdig"

};

const languageModalTranslations = {
  [ETranslation.LANGUAGE_MODAL_CHOOSE_LANGUAGE]: "Välj språk"
};

const openContactRequestModalTranslations = {
  [ETranslation.OPEN_CONTACT_MODAL_ARE_YOU_SURE]: "Är du säker du vill avbryta kontaktbegäran?",
  [ETranslation.OPEN_CONTACT_MODAL_YES]: "Ja",
  [ETranslation.OPEN_CONTACT_MODAL_NO]: "Nej",
  [ETranslation.OPEN_CONTACT_MODAL_SUBJECT]: "Du har lämnat kontaktbegäran om följande ämne: ",
  [ETranslation.OPEN_CONTACT_MODAL_RESPOND]: "Vi svarar så snabbt som möjigt.",
  [ETranslation.OPEN_CONTACT_MODAL_CLOSE]: "Stäng",
  [ETranslation.OPEN_CONTACT_MODAL_CANCEL]: "Avbryt",
};

const infoTranslations = {
  [ETranslation.INFO_CLOSE_CAPS]: "STÄNG"
};

const customFetchTranslations = {
  [ETranslation.ERROR_NO_AUTH]: "Fel! Användaren identifierades inte eller nödvändiga rättigheter finns inte."
};

const translationsSwe = {
  ...appTranslations,
  ...authTranslations,
  ...contactRequestTranslations,
  ...infoListTranslations,
  ...modalTranslations,
  ...contactRequestDataTranslations,
  ...languageModalTranslations,
  ...openContactRequestModalTranslations,
  ...infoTranslations,
  ...customFetchTranslations,
};

export default translationsSwe;
