import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import InfoItem from '../../components/InfoItem/InfoItem';
import Scrollable from '../../components/Scrollable/Scrollable';
import Alert from '../../components/ui/Alert/Alert';
import Button from '../../components/ui/Button/Button';
import Spinner from '../../components/ui/Spinner/Spinner';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const Info: React.FC<IProps> = ({ match, history }) => {
  const id = match.params.id;

  const { info, infoLoading, infoError } = useSelector(
    (state: IAppState) => state.info
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    dispatch(actions.getInfo(id));
  }, [id, dispatch]);

  return (
    <Scrollable>
      <>
        {infoError ? (
          <Alert>{infoError}</Alert>
        ) : infoLoading ? (
          <Spinner center />
        ) : (
          info && <InfoItem info={info} showLongDesc />
        )}

        <Button style={{ marginLeft: "1rem" }} onClick={() => history.goBack()}>
        {t(ETranslation.INFO_CLOSE_CAPS)}
        </Button>
      </>
    </Scrollable>
  );
};

export default Info;
