import 'firebase/auth';

import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import classes from './App.module.scss';
import { ERoutes } from './classes/Routes';
import Header from './components/Header/Header';
import Modal from './components/ui/Modal/Modal';
import ModalContext, { IModal } from './components/ui/Modal/ModalContext';
import Spinner from './components/ui/Spinner/Spinner';
import Info from './containers/Info/Info';
import InfoList from './containers/InfoList/InfoList';
import LanguageContext from './context/LanguageContext';
import { useLanguage } from './hooks/language-hook';
import { setToken } from './local-storage';
import { getUser, saveUser, updateUser } from './services/user-service';
import * as actions from './store/actions';
import { createLanguageModal } from './utils/modal-utils';
import { useTranslation } from 'react-i18next';

interface IProps {}

const defaultModal: IModal = {
  isOpen: false,
  content: null,
};

const App: React.FC<IProps> = () => {
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState(true);

  const { language, setLanguage } = useLanguage();

  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((fbUser) => {
      if (fbUser) {
        fbUser.getIdToken().then((token) => {
          setToken(token);
          dispatch(actions.findLanguages());

          getUser()
            .then((user) => {
              if (user.language !== language) {
                return updateUser({ id: user.id, language });
              } else {
                return user;
              }
            })
            .catch((err) => {
              return saveUser({
                language,
                phoneNumber: fbUser.phoneNumber,
              });
            })
            .then((user) => {
              dispatch(actions.setUser(user));
              setLoading(false);
            });
        });
      } else {
        setLoading(false);
        console.log("not logged in");
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    } else {
      createLanguageModal(setModal)
    }
  }, [setModal, language, i18n]);

  return (
    <div className={classes.Container}>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <ModalContext.Provider
          value={{
            setModal,
            closeModal: () => {
              if (modal.onModalClose) modal.onModalClose();
              setModal(defaultModal);
            },
          }}
        >
          <Modal modal={modal} />
          <Header />
          {loading ? (
            <Spinner center />
          ) : (
            <Switch>
              <Route path={ERoutes.INFO_LIST} exact component={InfoList}></Route>
              <Route path={ERoutes.INFO} exact component={Info}></Route>
              <Redirect to={ERoutes.INFO_LIST}></Redirect>
            </Switch>
          )}
        </ModalContext.Provider>
      </LanguageContext.Provider>
    </div>
  );
};

export default App;
