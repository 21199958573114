import React, { ReactNode } from 'react';

import classes from './ModalFooter.module.scss';





interface IProps {

  children: ReactNode;

}



const ModalFooter:React.FC<IProps> = ({ children }) => {

  return (

    <footer className={classes.Container}>

      {children}

    </footer>

  )

}



export default ModalFooter;