import settings from "../customer-settings/settings";
import { ETranslation } from "./translation-keys";

const appTranslations = {
  [ETranslation.COMMON_CLOSE]: "Sulje",
  [ETranslation.COMMON_SEND]: "Lähetä",
};

const authTranslations = {
  [ETranslation.AUTH_ENTER_PHONENUMBER]: "Jätä puhelinnumerosi",
  [ETranslation.AUTH_PHONENUMBER_TEXT]: "Syötä matkapuhelinnumero muodossa +358401234567. Ilman välimerkkejä ja välilyöntejä.",
  [ETranslation.AUTH_CONTINUE]: "Jatka",
  [ETranslation.AUTH_LOGIN_ID_ERROR_SENDING]: "Tunnuksen lähettäminen epäonnistui, tarkista puhelinnumero ja yritä uudelleen.",
  [ETranslation.AUTH_LOGIN_ID_ERROR]: "Virheellinen tunnus, yritä uudelleen.",
  [ETranslation.AUTH_LOGIN_ID_GIVE]: "Anna tunnus",
  [ETranslation.AUTH_LOGIN_ID_GET]: "Saat 30 sekunnin sisällä tunnuksen tekstiviestinä."
};

const contactRequestTranslations = {
  [ETranslation.CONTACT_REQUEST_SUBJECT]: "Aihe",
  [ETranslation.CONTACT_REQUEST_THANK_YOU]: "Kiitos yhteydenotosta! Vastaamme mahdollisimman pian.",
  [ETranslation.CONTACT_REQUEST_MUNICIPALITY]: "Asuinkunta",
  [ETranslation.CONTACT_REQUEST_CHOOSE_MUNICIPILATY]: "Valitse asuinkunta",
  [ETranslation.CONTACT_REQUEST_CHOOSE_SUBJECT]: "Valitse aihe",
  [ETranslation.CONTACT_REQUEST_INPUT_SUBJECT]: "Syötä aihe",
  [ETranslation.CONTACT_REQUEST_OTHER_SUBJECT]: "Muu aihe",
  [ETranslation.CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE]: "Tulkattava kieli",
  [ETranslation.CONTACT_REQUEST_I_NEED_INTERPRETER]: "Tarvitsen tulkin",
  [ETranslation.CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE]: "Valitse tulkattava kieli",
  [ETranslation.CONTACT_REQUEST_CONTACT_US_EMAIL]: "Voitte olla yhteydessä meihin myös sähköpostitse:",
};

const infoListTranslations = {
  [ETranslation.INFO_LIST_CONTACT_REQUEST]: "Jätä yhteydenottopyyntö",
  [ETranslation.INFO_LIST_OPEN_CONTACT_REQUEST]: "AVOIN YHTEYDENOTTOPYYNTÖ",
  [ETranslation.INFO_LIST_IDENTIFICATION]: "Tunnistaudu",
};
const modalTranslations = {
  [ETranslation.MODAL_CONTACT_REQUEST]: "Jätä yhteydenottopyyntö",
  [ETranslation.MODAL_LANGUAGE]: `${settings.name}n mobiilisovellus`,
};

const contactRequestDataTranslations = {
  [ETranslation.DATA_IMMIGRATION]: "Maahanmuuttoasiat",
  [ETranslation.DATA_PERMIT]: "Lupa-asiat (Migri)",
  [ETranslation.DATA_DECISIONS]: "Päätösten ja prosessien selventäminen, lomakkeet",
  [ETranslation.DATA_STUDYING]: "Opiskelu",
  [ETranslation.DATA_WORK]: "Työ ja työnhaku",
  [ETranslation.DATA_OTHER]: "Muu, mikä?",
  [ETranslation.DATA_NEW]: "Uusi",
  [ETranslation.DATA_IN_PROGRESS]: "Kesken",
  [ETranslation.DATA_COMPLETE]: "Valmis"

};

const languageModalTranslations = {
  [ETranslation.LANGUAGE_MODAL_CHOOSE_LANGUAGE]: "Valitse kieli"
};

const openContactRequestModalTranslations = {
  [ETranslation.OPEN_CONTACT_MODAL_ARE_YOU_SURE]: "Haluatko varmasti peruuttaa yhteydenottopyynnön?",
  [ETranslation.OPEN_CONTACT_MODAL_YES]: "Kyllä",
  [ETranslation.OPEN_CONTACT_MODAL_NO]: "Ei",
  [ETranslation.OPEN_CONTACT_MODAL_SUBJECT]: "Olet jättänyt yhteydenottopyynnön aiheesta:",
  [ETranslation.OPEN_CONTACT_MODAL_RESPOND]: "Vastaamme mahdollisimman pian.",
  [ETranslation.OPEN_CONTACT_MODAL_CLOSE]: "Sulje",
  [ETranslation.OPEN_CONTACT_MODAL_CANCEL]: "Peruuta",
};

const infoTranslations = {
  [ETranslation.INFO_CLOSE_CAPS]: "SULJE"
};

const customFetchTranslations = {
  [ETranslation.ERROR_NO_AUTH]: "Virhe! Käyttäjää ei tunnistettu tai ei oikeutta."
};


const translationsFi = {
  ...appTranslations,
  ...authTranslations,
  ...contactRequestTranslations,
  ...infoListTranslations,
  ...modalTranslations,
  ...contactRequestDataTranslations,
  ...languageModalTranslations,
  ...openContactRequestModalTranslations,
  ...infoTranslations,
  ...customFetchTranslations,
};

export default translationsFi;
