import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./translations/translation-en";
import translationsFi from "./translations/translation-fi";
import translationsRu from "./translations/translation-ru";
import translationsSwe from "./translations/translation-swe";

export enum ELanguage {
  FI = "fi",
  EN = "en",
  RU = "ru",
  AR = "ar",
  SV = "sv",
  FAS = "fas",
  TI = "ti",
  KMR = "kmr",
  TH = "th",
  ES = "es",
  ET = "et",
  UK = "uk",
  TR = "tr",
}

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  [ELanguage.EN]: {
    translation: translationsEn
  },
  [ELanguage.FI]: {
    translation: translationsFi
  },
  [ELanguage.RU]: {
    translation: translationsRu
  },
  [ELanguage.SV]: {
    translation: translationsSwe
  },
  [ELanguage.AR]: {
    translation: translationsFi
  },
  [ELanguage.FAS]: {
    translation: translationsFi
  },
  [ELanguage.TI]: {
    translation: translationsFi
  },
  [ELanguage.KMR]: {
    translation: translationsFi
  },
  [ELanguage.TH]: {
    translation: translationsFi
  },
  [ELanguage.ES]: {
    translation: translationsFi
  },
  [ELanguage.ET]: {
    translation: translationsFi
  },
  [ELanguage.UK]: {
    translation: translationsFi
  },
  [ELanguage.TR]: {
    translation: translationsFi
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: ELanguage.EN,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;