import EActionTypes from '../../interfaces/store/EActionTypes';
import IAuthAction from '../../interfaces/store/IAuthAction';
import IAuthState from '../../interfaces/store/IAuthState';

type ReducerAction = IAuthAction;
type ReducerState = IAuthState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  user: null
};

const setUser: ReducerSignature = (state, action): ReducerState => {
  return { ...state, user: action.user || null };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    
    case EActionTypes.AUTH_SET_USER:
      return setUser(state, action);
    default:
      return state;
  }
};

export default reducer;
