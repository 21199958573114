import { useState } from 'react';
import * as storage from './../local-storage';
import { useEffect } from 'react';


export const useLanguage = () => {
  const [language, setLanguage] = useState(storage.getLanguage());

  useEffect(() => {
    if(language) {
      storage.setLanguage(language);
    }

  }, [language]);

  return { language, setLanguage };
}