enum ELocalStorage {
  language = 'i-language',
  token = 'i-token',
}


export const setLanguage = (language: string) => {
  localStorage.setItem(ELocalStorage.language, language);
}

export const getLanguage = () => {
  return localStorage.getItem(ELocalStorage.language);
}

export const setToken = (token: string | null) => {
  if(token) {
    localStorage.setItem(ELocalStorage.token, token);
  } else {
    localStorage.removeItem(ELocalStorage.token);
  }
}

export const getToken = () => {
  return localStorage.getItem(ELocalStorage.token);
}