import { ECustomer } from "../config";
import { ICustomerSettings } from "./ICustomerSettings";

const settings: ICustomerSettings = {
  customer: ECustomer.alipi,
  name: "Alipi",
  domainName: "Alipi.fi",
  domainLink: "www.alipi.fi",
  email: "alipi@lahti.fi",
  cities: [
    "Asikkala",
    "Hartola",
    "Heinola",
    "Hollola",
    "Iitti",
    "Kärkölä",
    "Lahti",
    "Myrskylä",
    "Orimattila",
    "Padasjoki",
    "Pukkila",
    "Sysmä",
  ],
};

export default settings;
