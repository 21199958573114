import { IInfo } from './../../interfaces/IInfo';
import { Dispatch } from 'redux';

import { customFetch } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IInfoAction from '../../interfaces/store/IInfoAction';
import settings from '../../customer-settings/settings';

type TAction = IInfoAction;


const getInfoStart = (): TAction => {
  return {
    type: EActionTypes.INFO_GET_START
  };
};

const getInfoSuccess = (info: IInfo): TAction => {
  return {
    type: EActionTypes.INFO_GET_SUCCESS,
    info,
  };
};

const getInfoFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_GET_FAIL,
    error,
  };
};

export const getInfoClear = (): TAction => {
  return {
    type: EActionTypes.INFO_GET_CLEAR,
  };
};

export const getInfo = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getInfoStart());
      const info = await customFetch<IInfo>(`/newsitems/get?id=${id}`);
      dispatch(getInfoSuccess(info));
    } catch (error) {
      dispatch(getInfoFail(error.message));
    }
  };
};

const findInfosStart = (): TAction => {
  return {
    type: EActionTypes.INFO_FIND_START
  };
};

const findInfosSuccess = (infos: IInfo[]): TAction => {
  return {
    type: EActionTypes.INFO_FIND_SUCCESS,
    infos
  };
};

const findInfosFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_FIND_FAIL,
    error,
  };
};

export const findInfoClear = (): TAction => {
  return {
    type: EActionTypes.INFO_FIND_CLEAR,
  };
};

export const findInfos = (displayLanguage: string | null) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findInfosStart());

      const queryList = [`displayLocation=${settings.customer}`];
      if(displayLanguage) {
        queryList.push(`displayLanguage=${displayLanguage}`);
      }

      const infos = await customFetch<IInfo[]>(`/newsitems/list?${queryList.join('&')}`);
      dispatch(findInfosSuccess(infos));
    } catch (error) {
      dispatch(findInfosFail(error.message));
    }
  };
};