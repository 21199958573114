export enum ETranslation {
  //HomePage
  INFO_LIST_CONTACT_REQUEST = "INFO_LIST_CONTACT_REQUEST",

  CONTACT_REQUEST_MUNICIPALITY = "CONTACT_REQUEST_MUNICIPALITY",
  CONTACT_REQUEST_I_NEED_INTERPRETER = "CONTACT_REQUEST_I_NEED_INTERPRETER",

  //AuthModal
  AUTH_ENTER_PHONENUMBER = "AUTH_ENTER_PHONENUMBER",

  AUTH_PHONENUMBER_TEXT = "PHONENUMBERINFOTEXT",
  AUTH_CONTINUE = "CONTINUE",
  AUTH_LOGIN_ID_ERROR_SENDING = "AUTH_LOGIN_ID_ERROR_SENDING",
  AUTH_LOGIN_ID_ERROR = "AUTH_LOGIN_ID_ERROR",
  AUTH_LOGIN_ID_GIVE = "AUTH_LOGIN_ID_GIVE",
  AUTH_LOGIN_ID_GET = "AUTH_LOGIN_ID_GET",

  //ContactRequestModal
  CONTACT_REQUEST_SUBJECT = "SUBJECT",
  CONTACT_REQUEST_THANK_YOU = "CONTACT_REQUEST_THANK_YOU",
  CONTACT_REQUEST_CHOOSE_SUBJECT = "CONTACT_REQUEST_CHOOSE_SUBJECT",
  CONTACT_REQUEST_CHOOSE_MUNICIPILATY = "CONTACT_REQUEST_CHOOSE_MUNICIPILATY",
  CONTACT_REQUEST_INPUT_SUBJECT = "CONTACT_REQUEST_INPUT_SUBJECT",
  CONTACT_REQUEST_OTHER_SUBJECT = "CONTACT_REQUEST_OTHER_SUBJECT",
  CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE = "CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE",
  CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE = "CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE",
  CONTACT_REQUEST_CONTACT_US_EMAIL = "CONTACT_REQUEST_CONTACT_US_EMAIL",
  
  COMMON_SEND = "COMMON_SEND",
  COMMON_CLOSE = "COMMON_CLOSE",

  INFO_LIST_OPEN_CONTACT_REQUEST = "INFO_LIST_OPEN_CONTACT_REQUEST",
  INFO_LIST_IDENTIFICATION = "INFO_LIST_IDENTIFICATION",
  DATA_IMMIGRATION = "DATA_IMMIGRATION",
  DATA_PERMIT = "DATA_PERMIT",
  DATA_DECISIONS = "DATA_DECISIONS",
  DATA_STUDYING = "DATA_STUDYING",
  DATA_WORK = "DATA_WORK",
  DATA_OTHER = "DATA_OTHER",
  DATA_NEW = "DATA_NEW",
  DATA_IN_PROGRESS = "DATA_IN_PROGRESS",
  DATA_COMPLETE = "DATA_COMPLETE",
  LANGUAGE_MODAL_CHOOSE_LANGUAGE = "LANGUAGE_MODAL_CHOOSE_LANGUAGE",
  OPEN_CONTACT_MODAL_ARE_YOU_SURE = "OPEN_CONTACT_MODAL_ARE_YOU_SURE",
  OPEN_CONTACT_MODAL_YES = "OPEN_CONTACT_MODAL_YES",
  OPEN_CONTACT_MODAL_NO = "OPEN_CONTACT_MODAL_NO",
  OPEN_CONTACT_MODAL_SUBJECT = "OPEN_CONTACT_MODAL_SUBJECT",
  OPEN_CONTACT_MODAL_RESPOND = "OPEN_CONTACT_MODAL_RESPOND",
  OPEN_CONTACT_MODAL_CLOSE = "OPEN_CONTACT_MODAL_CLOSE",
  OPEN_CONTACT_MODAL_CANCEL = "OPEN_CONTACT_MODAL_CANCEL",
  INFO_CLOSE_CAPS = "INFO_CLOSE_CAPS",
  ERROR_NO_AUTH = "ERROR_NO_AUTH",

  MODAL_CONTACT_REQUEST = "MODAL_CONTACT_REQUEST",
  MODAL_LANGUAGE = "MODAL_LANGUAGE",
} 
