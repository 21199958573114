import 'firebase/auth';

import { faBars, faGlobe, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IAppState from '../../interfaces/store/IAppState';
import { setToken } from '../../local-storage';
import * as actions from '../../store/actions';
import { createLanguageModal } from '../../utils/modal-utils';
import ModalContext from '../ui/Modal/ModalContext';
import classes from './Header.module.scss';
import Menu from './Menu/Menu';
import settings from '../../customer-settings/settings';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);

  const user = useSelector((state: IAppState) => state.auth.user);
  const dispatch = useDispatch();
  
  const { setModal } = useContext(ModalContext);

  const signOutHandler = () => {
    setToken(null);
    firebase.auth().signOut();
    dispatch(actions.setUser(null))
  }

  const changeLanguageHandler = () => {
    createLanguageModal(setModal);
  }

  return (
    <React.Fragment>
      <Menu isOpen={isOpen} toggleMenu={toggleMenu} />
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.ToggleMenu} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} className={classes.Icon} />
          </div>
          <div className={classes.Title}>
          {settings.domainName}
          </div>
          <div className={classes.IconButtons}>
            <FontAwesomeIcon icon={faGlobe} onClick={changeLanguageHandler} /> 
            {user && (<FontAwesomeIcon icon={faSignOutAlt} onClick={signOutHandler} /> )}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default React.memo(Header);
