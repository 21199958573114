import React from 'react';

import ContactRequestModal from '../components/ContactRequestModal/ContactRequestModal';
import LanguageModal from '../components/LanguageModal/LanguageModal';
import { TSetModal } from '../components/ui/Modal/ModalContext';
import { ETranslation } from '../translations/translation-keys';


export const createContactRequestModal = (setModal: TSetModal) => {
  setModal({
    isOpen: true,
    titleTranslation: ETranslation.MODAL_CONTACT_REQUEST,
    content: <ContactRequestModal />
  })
}

export const createLanguageModal = (setModal: TSetModal) => {
  setModal({
    isOpen: true,
    content: <LanguageModal />,
    titleTranslation: ETranslation.MODAL_LANGUAGE,
    disabled: true,
  });

}