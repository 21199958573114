import React from "react";

import { IInfo } from "../../interfaces/IInfo";
import ImageSpinner from "../ui/ImageSpinner/ImageSpinner";

import classes from "./InfoItem.module.scss";

import * as dateFns from 'date-fns';

interface IProps {
  info: IInfo;
  onClick?: (id: number) => void;
  showLongDesc?: boolean;
}

const InfoItem: React.FC<IProps> = ({ info, onClick, showLongDesc }) => {
  const classNames = [classes.Container];

  if (onClick) {
    classNames.push(classes.Clickable);
  }


  

  return (
    <div
      className={classNames.join(" ")}
      onClick={onClick ? () => onClick(info.id) : undefined}
    >
      <h3 className={classes.Title}>{info.title}</h3>
      {info.showPublishDate ? (
        <p className={classes.Date}>{dateFns.format(dateFns.parseISO(info.publishDate), 'dd.MM.yyyy HH:mm')}</p>
      ) : (
        <br />
      )}
      {info.imagePublicUrl && (
        <ImageSpinner className={classes.Image} src={info.imagePublicUrl} alt={info.title} />
      )}
      <p
        className={classes.Description}
        dangerouslySetInnerHTML={{
          __html: showLongDesc ? info.contentText : info.shortContent,
        }}
      ></p>
    </div>
  );
};

export default InfoItem;
