import { Dispatch } from 'redux';

import { customFetch } from '../../custom-fetch';
import settings from '../../customer-settings/settings';
import { ILanguage } from '../../interfaces/ILanguage';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ILanguageAction from '../../interfaces/store/ILanguageAction';

type TAction = ILanguageAction;


const findLanguagesStart = (): TAction => {
  return {
    type: EActionTypes.LANGUAGE_FIND_START
  };
};

const findLanguagesSuccess = (languages: ILanguage[]): TAction => {
  return {
    type: EActionTypes.LANGUAGE_FIND_SUCCESS,
    languages,
  };
};

const findLanguagesFail = (error: string): TAction => {
  return {
    type: EActionTypes.LANGUAGE_FIND_FAIL,
    error,
  };
};

export const findLanguagesClear = (): TAction => {
  return {
    type: EActionTypes.LANGUAGE_FIND_CLEAR,
  };
};



export const findLanguages = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findLanguagesStart());
      const resp = await customFetch<{ languages: ILanguage[]}>(`/languages?displayLocation=${settings.customer}`);
      dispatch(findLanguagesSuccess(resp.languages));
    } catch (error) {
      dispatch(findLanguagesFail(error.message));
    }
  };
};