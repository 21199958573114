import 'firebase/auth';

import firebase from 'firebase/app';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { createContactRequestModal } from '../../utils/modal-utils';
import Alert from '../ui/Alert/Alert';
import Button from '../ui/Button/Button';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import SimpleInput, { EInputType } from '../ui/SimpleInput/SimpleInput';
import Spinner from '../ui/Spinner/Spinner';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';


enum EView {
  PHONE_NUMBER, CONFIRM_CODE
}

interface IProps {

}

const AuthModal:React.FC<IProps> = () => {
  const [view, setView] = useState<EView>(EView.PHONE_NUMBER);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null);
  const [loading, setLoading] = useState(false);
  const submitPhoneNumberRef = useRef<HTMLButtonElement>(null);
  const [verifier, setVerifier] = useState<firebase.auth.RecaptchaVerifier | null>(null)
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const submitPhoneNumberHandler = useCallback(async (verifier: firebase.auth.RecaptchaVerifier) => {
    setError(null);
    try {
      setLoading(true);
      const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, verifier);
      setConfirmationResult(confirmationResult);
      setView(EView.CONFIRM_CODE);
    } catch(error) {
      setError(ETranslation.AUTH_LOGIN_ID_ERROR_SENDING);
      console.log("phoneNumber error:", error);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber]);
  
  useEffect(() => {
    if(submitPhoneNumberRef.current) {
      const verifier = new firebase.auth.RecaptchaVerifier(submitPhoneNumberRef.current, {
        'size': 'invisible',
        'callback': () => {
          setIsVerified(true);
        }
      });
      verifier.render();
      setVerifier(verifier);
    }
  }, [submitPhoneNumberRef]);

  useEffect(() => {
    if(isVerified && verifier) {
      submitPhoneNumberHandler(verifier)
    }
    // eslint-disable-next-line
  }, [isVerified, verifier]);

  const submitConfirmCodeHandler = async () => {
    setError(null);
    if(confirmationResult) {
      try {
        setLoading(true);
        await confirmationResult.confirm(confirmCode);
        createContactRequestModal(setModal)
      } catch(error) {
        setError(ETranslation.AUTH_LOGIN_ID_ERROR);
        console.log("confirmCode error:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <ModalBody>
      {error && (<Alert>{error}</Alert>)}
      {(() => {
        if(loading) {
          return (
            <Spinner center />
          )
        }

        switch (view) {
          case EView.PHONE_NUMBER:
            return (
              <>
                <SimpleInput 
                  onChange={setPhoneNumber} 
                  value={phoneNumber} 
                  type={EInputType.text} 
                  labelTranslation={ETranslation.AUTH_ENTER_PHONENUMBER}
                  placeholder="+358401234567"
                  info= {t(ETranslation.AUTH_PHONENUMBER_TEXT)}
                  />
                <Button onClick={() => {
                  if(isVerified && verifier) {
                    submitPhoneNumberHandler(verifier);
                  }
                }} buttonRef={submitPhoneNumberRef}>{t(ETranslation.AUTH_CONTINUE)}</Button>        
              </>
            );
          case EView.CONFIRM_CODE:
            return (
              <>
                <SimpleInput 
                  onChange={setConfirmCode} 
                  value={confirmCode} 
                  type={EInputType.text} 
                  labelTranslation={ETranslation.AUTH_LOGIN_ID_GIVE}
                  info= {t(ETranslation.AUTH_LOGIN_ID_GET)} 
                  />
                <Button onClick={submitConfirmCodeHandler}>{t(ETranslation.AUTH_CONTINUE)}</Button>        
              </>
            );
        }
      })()}
    </ModalBody>
  )
}


export default AuthModal;