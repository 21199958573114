import 'firebase/auth';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoutes, Routes } from '../../classes/Routes';
import AuthModal from '../../components/AuthModal/AuthModal';
import InfoItem from '../../components/InfoItem/InfoItem';
import OpenContactRequestModal from '../../components/OpenContactRequestModal/OpenContactRequestModal';
import Alert from '../../components/ui/Alert/Alert';
import ModalContext from '../../components/ui/Modal/ModalContext';
import Spinner from '../../components/ui/Spinner/Spinner';
import LanguageContext from '../../context/LanguageContext';
import { IContactRequest } from '../../interfaces/IContactRequest';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import { createContactRequestModal } from '../../utils/modal-utils';
import classes from './InfoList.module.scss';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps extends RouteComponentProps {}

const InfoList: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(actions.findInfos(language));
  }, [dispatch, language]);

  const { infos, infosLoading, infosError, user } = useSelector(
    (state: IAppState) => ({
      ...state.info,
      user: state.auth.user,
    })
  );

  const { setModal } = useContext(ModalContext);

  const openHandler = (id: number) => {
    history.push(Routes.withParams(ERoutes.INFO, { id: id.toString() }));
  };

  const contactRequestHandler = () => {
    if (user) {
      createContactRequestModal(setModal);
    } else {
      setModal({
        isOpen: true,
        titleTranslation: ETranslation.INFO_LIST_IDENTIFICATION,
        content: <AuthModal />,
      });
    }
  };

  const openContactRequestHandler = (contactRequest: IContactRequest) => {
    setModal({
      isOpen: true,
      titleTranslation: ETranslation.INFO_LIST_CONTACT_REQUEST,
      content: <OpenContactRequestModal contactRequest={contactRequest} />,
    });
  };

  const InfoListButton: React.FC<{
    onClick: () => void;
    children: ReactNode;
    className: string;
  }> = ({ onClick, children, className }) => {
    return (
      <div className={classes.ButtonContainer}>
        <button
          className={[classes.Button, className].join(" ")}
          onClick={onClick}
        >
          <div className={classes.ButtonIcon}>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
          {children}
        </button>
      </div>
    );
  };

  const openContactRequest = user?.openContactRequest;

  return (
    <div className={classes.Container}>
      <div className={classes.ListContainer}>
        {infosError ? (
          <Alert>{infosError}</Alert>
        ) : infosLoading ? (
          <Spinner center />
        ) : (
          infos &&
          infos.map((info) => (
            <InfoItem key={info.id} info={info} onClick={openHandler} />
          ))
        )}
      </div>
      {openContactRequest ? (
        <InfoListButton
          onClick={() => openContactRequestHandler(openContactRequest)}
          className={classes.OpenContactRequest}
        >
          {t(ETranslation.INFO_LIST_OPEN_CONTACT_REQUEST)}
        </InfoListButton>
      ) : (
        <InfoListButton
          onClick={contactRequestHandler}
          className={classes.ContactRequest}
        >
          {t(ETranslation.INFO_LIST_CONTACT_REQUEST)}
        </InfoListButton>
      )}
    </div>
  );
};

export default InfoList;
