import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  INFO_CONTACT_REQUEST_MUNICIPALITIES,
  INFO_CONTACT_REQUEST_SUBJECTS as CONTACT_REQUEST_SUBJECTS,
} from '../../data/contact-request-data';
import { EContactRequestSubject } from '../../interfaces/IContactRequest';
import { IUser } from '../../interfaces/IUser';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import Alert from '../ui/Alert/Alert';
import Button from '../ui/Button/Button';
import IconInput from '../ui/IconInput/IconInput';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';
import SimpleInput, { EInputType, IOption } from '../ui/SimpleInput/SimpleInput';
import Spinner from '../ui/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';
import settings from '../../customer-settings/settings';

/*
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons';
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
*/


const ContactRequestModal: React.FC = () => {
  const [municipality, setMunicipality] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectOther, setSubjectOther] = useState("");
  const [toLanguage, setToLanguage] = useState("");
  const [needInterpreter, setNeedInterpreter] = useState(false);
  // const [urgent, setUrgent] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const {
    saveOrUpdateContactRequest,
    saveOrUpdateLoading,
    saveOrUpdateError,
    user,
    languages
  } = useSelector((appState: IAppState) => ({
    ...appState.contactRequest,
    user: appState.auth.user as IUser,
    languages: appState.language.languages
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (saveOrUpdateContactRequest) {
      user.openContactRequest = saveOrUpdateContactRequest;
      dispatch(actions.setUser(user));
      dispatch(actions.saveContactRequestClear());
      setSubmitted(true);
    }
  }, [saveOrUpdateContactRequest, user, dispatch]);

  useEffect(() => {
    if (languages) {
      setLanguageOptions(languages.map(lang => ({ value: lang.id, label: lang.name })));
    }

  }, [languages]);

  const submitHandler = () => {
    dispatch(actions.saveContactRequest({ 
      subject: subject as EContactRequestSubject, 
      subjectOther, 
      municipality, 
      toLanguage, 
      needInterpreter 
    }));
  };

  if (saveOrUpdateLoading) {
    return <Spinner center />;
  }

  const isValid = () => {
    return subject.length && municipality.length && (!needInterpreter || toLanguage.length);
  }

  return isSubmitted ? (
    <>
      <ModalBody>
        <p>{t(ETranslation.CONTACT_REQUEST_THANK_YOU)}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>
        {t(ETranslation.COMMON_CLOSE)}
        </Button>
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        {saveOrUpdateError && <Alert>{saveOrUpdateError}</Alert>}
        <SimpleInput
          labelTranslation={ETranslation.CONTACT_REQUEST_MUNICIPALITY}
          placeholderTranslation={ETranslation.CONTACT_REQUEST_CHOOSE_MUNICIPILATY}
          type={EInputType.select}
          onChange={setMunicipality}
          value={municipality}
          options={INFO_CONTACT_REQUEST_MUNICIPALITIES}
        />
        <SimpleInput
          labelTranslation={ETranslation.CONTACT_REQUEST_SUBJECT}
          placeholderTranslation={ETranslation.CONTACT_REQUEST_CHOOSE_SUBJECT}
          type={EInputType.select}
          onChange={setSubject}
          value={subject}
          options={CONTACT_REQUEST_SUBJECTS}
        />
        {subject === EContactRequestSubject.OTHER && (
          <SimpleInput
            placeholderTranslation={ETranslation.CONTACT_REQUEST_INPUT_SUBJECT}
            labelTranslation={ETranslation.CONTACT_REQUEST_OTHER_SUBJECT}
            type={EInputType.text}
            onChange={setSubjectOther}
            value={subjectOther}
          />
        )}
        <IconInput 
          labelTranslation= {ETranslation.CONTACT_REQUEST_I_NEED_INTERPRETER} 
          checked={needInterpreter} 
          onChange={setNeedInterpreter} 
          icon={faSquare} iconChecked={faCheckSquare} />

        {needInterpreter && (
          <SimpleInput
            labelTranslation={ETranslation.CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE} 
            placeholderTranslation={ETranslation.CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE} 
            type={EInputType.select}
            onChange={setToLanguage}
            value={toLanguage}
            options={languageOptions}
          />
        )}
        <i>{t(ETranslation.CONTACT_REQUEST_CONTACT_US_EMAIL)} <a href={`mailto:${settings.email}`}>{settings.email}</a></i>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!isValid()} onClick={submitHandler}>
        {t(ETranslation.COMMON_SEND)}
        </Button>
      </ModalFooter>
    </>
  );
};

/* <IconInput label="Kiireellinen" checked={urgent} onChange={setUrgent} icon={faBellRegular} iconChecked={faBellSolid} /> */

export default ContactRequestModal;
