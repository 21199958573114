import EActionTypes from '../../interfaces/store/EActionTypes';
import ILanguageAction from '../../interfaces/store/ILanguageAction';
import ILanguageState from '../../interfaces/store/ILanguageState';

type ReducerAction = ILanguageAction;
type ReducerState = ILanguageState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  languages: null,
  loading: false,
  error: null

};

const findLanguagesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: true, error: null, languages: null};
};

const findLanguagesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    languages: action.languages || null,
    error: null,
    loading: false,
  };
};

const findLanguagesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languages: null, loading: false, error: action.error || "Virhe! Kielten haku epäonnistui." };
};

const findLanguagesClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languages: null, loading: false, error: null};
};


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.LANGUAGE_FIND_START:
      return findLanguagesStart(state, action);
    case EActionTypes.LANGUAGE_FIND_SUCCESS:
      return findLanguagesSuccess(state, action);
    case EActionTypes.LANGUAGE_FIND_FAIL:
      return findLanguagesFail(state, action);
    case EActionTypes.LANGUAGE_FIND_CLEAR:
      return findLanguagesClear(state, action);
    default:
      return state;
  }
};

export default reducer;
