import React from "react";


const LanguageContext = React.createContext<{
  setLanguage: React.Dispatch<React.SetStateAction<string | null>>;
  language: string | null;
}>({
  setLanguage: (language) => {},
  language: null
});

export default LanguageContext;
