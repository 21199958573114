import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import { ERoutes } from '../../../classes/Routes';
import settings from '../../../customer-settings/settings';
import { useHideOverflow } from '../../../hooks/overflow-hook';
import classes from './Menu.module.scss';


interface IProps extends RouteComponentProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

interface IMenuItem {
  text: string;
  link?: string;
  to?: string;
  subMenuItems?: IMenuItem[];
}

const Menu: React.FC<IProps> = ({ isOpen, toggleMenu, location }) => {
  const menuItems: IMenuItem[] = [];
  menuItems.push(
    {
      text: settings.name,
      to: ERoutes.INFO_LIST
    },
    {
      text: settings.domainName,
      link: `https://${settings.domainLink}`
    },
  );

  useHideOverflow(isOpen);

  const classNames = [classes.Menu];

  if (isOpen) {
    classNames.push(classes.isOpen);
  }

  const menuItemClickHandler = (menuItem: IMenuItem): void => {
    if (menuItem.link) {
      window.open(menuItem.link);
    }
  };

  const isActiveChild = (menuItems?: IMenuItem[]): boolean => {
    if (!menuItems) return false;
    return menuItems.some(
      (menuItem) => menuItem.to && location.pathname.startsWith(menuItem.to)
    );
  };

  const renderMenuItem = (menuItem: IMenuItem) => {
    const key = menuItem.text;
    let item: JSX.Element | null = null;
    if (menuItem.to) {
      item = (
        <NavLink
          to={menuItem.to}
          className={classes.MenuItem}
          activeClassName={classes.MenuItemActive}
        >
          {menuItem.text}
        </NavLink>
      );
    } else {
      const classNames = [classes.MenuItem];
      const isActive = isActiveChild(menuItem.subMenuItems);
      if (isActive) {
        classNames.push(classes.MenuItemActive);
      }
      item = (
        <div
          className={classNames.join(" ")}
          onClick={() => menuItemClickHandler(menuItem)}
        >
          {menuItem.text}{" "}
          {menuItem.subMenuItems && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={classes.MenuIcon}
            />
          )}
        </div>
      );
    }

    return (
      <div className={classes.MenuItemContainer} key={key}>
        {item}
        {menuItem.subMenuItems && (
          <div className={classes.Dropdown}>
            {menuItem.subMenuItems.map((menuItem) => renderMenuItem(menuItem))}
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={classNames.join(" ")}>
        <div className={classes.MobileHeader}>
          <span className={classes.Toggle} onClick={toggleMenu}>
            &times;
          </span>
        </div>
        {menuItems.map((menuItem) => renderMenuItem(menuItem))}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Menu);
