import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "../../data/language-data";
import IAppState from "../../interfaces/store/IAppState";
import { updateUser } from "../../services/user-service";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalContext from "../ui/Modal/ModalContext";

import classes from "./LanguageModal.module.scss";

import * as actions from "../../store/actions";
import Spinner from "../ui/Spinner/Spinner";
import LanguageContext from "../../context/LanguageContext";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

const LanguageModal: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);

  const { setLanguage, language } = useContext(LanguageContext);
  const { t } = useTranslation();

  const user = useSelector((state: IAppState) => state.auth.user);
  const dispatch = useDispatch();

  const submitHandler = (language: string) => {
    if (user) {
      setLoading(true);
      updateUser({ language }).then((user) => {
        dispatch(actions.setUser(user));
        setLoading(false);
        setLanguage(language);
        closeModal();
      });
    } else {
      setLanguage(language);
      closeModal();
    }
  };


  const getClassNames = (isSelected: boolean) => {
    const classNames = [classes.LanguageItem];
    if(isSelected) {
      classNames.push(classes.Selected);
    }
    return classNames.join(' ');
  }

  return (
    <>
      <ModalBody>
        {loading ? (
          <Spinner center />
        ) : (
          <>
          <h3 style={{ marginTop: 0}}>{t(ETranslation.LANGUAGE_MODAL_CHOOSE_LANGUAGE)}</h3>
          <div className={classes.LanguageContainer}>
            {languages.map((item) => (
              <div
                key={item.id}
                className={getClassNames(item.id === language)}
                onClick={() => submitHandler(item.id)}
              >
                <p>{item.name}</p>
              </div>
            ))}
          </div>
          </>
        )}
      </ModalBody>
    </>
  );
};

export default LanguageModal;
