import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IContactRequestAction from '../../interfaces/store/IContactRequestAction';
import { IContactRequest } from './../../interfaces/IContactRequest';

type TAction = IContactRequestAction;


const saveContactRequestStart = (): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_START
  };
};

const saveContactRequestSuccess = (contactRequest: IContactRequest): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_SUCCESS,
    contactRequest,
  };
};

const saveContactRequestFail = (error: string): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const saveContactRequestClear = (): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_CLEAR,
  };
};

export const saveContactRequest = (contactRequest: Partial<IContactRequest>) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveContactRequestStart());
      const savedContactRequest = await customFetch<IContactRequest>(`/infocontactrequests/add`, EFetchMethod.POST, JSON.stringify(contactRequest));
      dispatch(saveContactRequestSuccess(savedContactRequest));
    } catch (error) {
      dispatch(saveContactRequestFail(error.message));
    }
  };
};


const cancelContactRequestStart = (): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_CANCEL_START
  };
};

const cancelContactRequestSuccess = (): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_CANCEL_SUCCESS,
  };
};

const cancelContactRequestFail = (error: string): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_CANCEL_FAIL,
    error,
  };
};

export const cancelContactRequestClear = (): TAction => {
  return {
    type: EActionTypes.CONTACT_REQUEST_CANCEL_CLEAR,
  };
};

export const cancelContactRequest = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(cancelContactRequestStart());
      await customFetch<IContactRequest>(`/infocontactrequests/cancel`, EFetchMethod.PUT, JSON.stringify({ id }));
      dispatch(cancelContactRequestSuccess());
    } catch (error) {
      dispatch(cancelContactRequestFail(error.message));
    }
  };
};