import EActionTypes from '../../interfaces/store/EActionTypes';
import IAuthAction from '../../interfaces/store/IAuthAction';
import { IUser } from '../../interfaces/IUser';

type TAction = IAuthAction;


export const setUser = (user: IUser | null): TAction => {
  return {
    type: EActionTypes.AUTH_SET_USER,
    user
  };
};