import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";

import classes from "./Modal.module.scss";
import ModalContext, { defaultModal, IModal } from "./ModalContext";
import Backdrop from "../Backdrop/Backdrop";
import Spinner from "../Spinner/Spinner";
import { useTranslation } from "react-i18next";

interface IProps {
  modal: IModal;
}

const Modal: React.FC<IProps> = ({ modal }) => {
  const { setModal } = useContext(ModalContext);
  const { t }  = useTranslation()
  const containerClassNames = [classes.Container];

  if (modal.isOpen) {
    containerClassNames.push(classes.ContainerOpen);
  }

  const closeModal = () => {
    if (modal.disabled) return;
    setModal(defaultModal);
    if (modal.onModalClose) modal.onModalClose();
  };

  const contentClassNames = [classes.Content];
  if(modal.noScroll) {
    contentClassNames.push(classes.NoScroll);
  }
  return (
    <React.Fragment>
      <Backdrop onClick={closeModal} isOpen={modal.isOpen} />
      <div className={containerClassNames.join(" ")} onClick={closeModal}>
        <div
          className={contentClassNames.join(' ')}
          onClick={(event) => event.stopPropagation()}
        >
          <header className={classes.Header}>
            <h2 className={classes.Title}>{modal.titleTranslation ? t(modal.titleTranslation) : modal.title}</h2>
            {!modal.disabled && (
              <span className={classes.CloseModal} onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            )}
          </header>
          {modal.loading ? <Spinner /> : modal.content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
