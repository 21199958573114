import settings from '../customer-settings/settings';
import { ETranslation } from './translation-keys';

const appTranslations = {
  [ETranslation.COMMON_CLOSE]: "Close",
  [ETranslation.COMMON_SEND]: "Send",
};

const authTranslations = {
  [ETranslation.AUTH_ENTER_PHONENUMBER]: "Enter your phone number",
  [ETranslation.AUTH_PHONENUMBER_TEXT]: "Please provide your mobile phone number in the form +358401234567 without any punctuation marks and spaces.",
  [ETranslation.AUTH_CONTINUE]: "Continue",
  [ETranslation.AUTH_LOGIN_ID_ERROR_SENDING]: "Sending of verification code failed, please check your phone number and try again.",
  [ETranslation.AUTH_LOGIN_ID_ERROR]: "Incorrect verification code, please try again.",
  [ETranslation.AUTH_LOGIN_ID_GIVE]: "Enter your verification code",
  [ETranslation.AUTH_LOGIN_ID_GET]: "You will receive your verification code by text message within 30 seconds."
};

const contactRequestTranslations = {
  [ETranslation.CONTACT_REQUEST_SUBJECT]: "Subject",
  [ETranslation.CONTACT_REQUEST_THANK_YOU]: "Thank you for contacting us! We will respond to your request as soon as possible.",
  [ETranslation.CONTACT_REQUEST_MUNICIPALITY]: "Municipality of residence",
  [ETranslation.CONTACT_REQUEST_CHOOSE_MUNICIPILATY]: "Choose your municipality of residence",
  [ETranslation.CONTACT_REQUEST_CHOOSE_SUBJECT]: "Choose subject",
  [ETranslation.CONTACT_REQUEST_INPUT_SUBJECT]: "Enter subject",
  [ETranslation.CONTACT_REQUEST_OTHER_SUBJECT]: "Other subject",
  [ETranslation.CONTACT_REQUEST_INTERPRETATIONS_LANGUAGE]: "Interpretation's language",
  [ETranslation.CONTACT_REQUEST_I_NEED_INTERPRETER]: "I need an interpreter",
  [ETranslation.CONTACT_REQUEST_CHOOSE_INTERPRETATIONS_LANGUAGE]: "Choose your language to be interpreted",
  [ETranslation.CONTACT_REQUEST_CONTACT_US_EMAIL]: "You can also contact us by e-mail:",
};

const infoListTranslations = {
  [ETranslation.INFO_LIST_CONTACT_REQUEST]: "Leave a contact request",
  [ETranslation.INFO_LIST_OPEN_CONTACT_REQUEST]: "OPEN CONTACT REQUEST",
  [ETranslation.INFO_LIST_IDENTIFICATION]: "Identification",
};

const modalTranslations = {
  [ETranslation.MODAL_CONTACT_REQUEST]: "Leave a contact request",
  [ETranslation.MODAL_LANGUAGE]: `${settings.name} Mobile Application`,
};

const contactRequestDataTranslations = {
  [ETranslation.DATA_IMMIGRATION]: "Immigration matters",
  [ETranslation.DATA_PERMIT]: "Permit matters (Finnish Immigration Service, Migri)",
  [ETranslation.DATA_DECISIONS]: "Clarification of decisions and processes, forms",
  [ETranslation.DATA_STUDYING]: "Studying",
  [ETranslation.DATA_WORK]: "Work and applying for job",
  [ETranslation.DATA_OTHER]: "Other, what?",
  [ETranslation.DATA_NEW]: "New",
  [ETranslation.DATA_IN_PROGRESS]: "In progress",
  [ETranslation.DATA_COMPLETE]: "Complete"

};

const languageModalTranslations = {
  [ETranslation.LANGUAGE_MODAL_CHOOSE_LANGUAGE]: "Choose language"
};

const openContactRequestModalTranslations = {
  [ETranslation.OPEN_CONTACT_MODAL_ARE_YOU_SURE]: "Are you sure you want to cancel your contact request?",
  [ETranslation.OPEN_CONTACT_MODAL_YES]: "Yes",
  [ETranslation.OPEN_CONTACT_MODAL_NO]: "No",
  [ETranslation.OPEN_CONTACT_MODAL_SUBJECT]: "You have submitted a contact request on the following subject: ",
  [ETranslation.OPEN_CONTACT_MODAL_RESPOND]: "We will respond as soon as possible.",
  [ETranslation.OPEN_CONTACT_MODAL_CLOSE]: "Close",
  [ETranslation.OPEN_CONTACT_MODAL_CANCEL]: "Cancel",
};

const infoTranslations = {
  [ETranslation.INFO_CLOSE_CAPS]: "CLOSE"
};

const customFetchTranslations = {
  [ETranslation.ERROR_NO_AUTH]: "Error! The user was not identified or they do not have the required rights."
};

const translationsEn = {
  ...appTranslations,
  ...authTranslations,
  ...contactRequestTranslations,
  ...infoListTranslations,
  ...modalTranslations,
  ...contactRequestDataTranslations,
  ...languageModalTranslations,
  ...openContactRequestModalTranslations,
  ...infoTranslations,
  ...customFetchTranslations,
};

export default translationsEn;
