import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCewTCprN-REGBYZtNut5HTxwXJNCIER2k",
  authDomain: "pospinterpreter.firebaseapp.com",
  databaseURL: "https://pospinterpreter.firebaseio.com",
  projectId: "pospinterpreter",
  storageBucket: "pospinterpreter.appspot.com",
  messagingSenderId: "499510980968",
  appId: "1:499510980968:web:2a9e9e396f8c767074e6dd",
  measurementId: "G-K0K02MDSLP"
};


const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
}

export default initializeFirebase;