import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInfoContactRequestSubjectString } from "../../data/contact-request-data";

import { IContactRequest } from "../../interfaces/IContactRequest";
import Button, { EButtonColor } from "../ui/Button/Button";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalContext from "../ui/Modal/ModalContext";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";

import * as actions from '../../store/actions';
import IAppState from "../../interfaces/store/IAppState";
import Alert from "../ui/Alert/Alert";
import { IUser } from "../../interfaces/IUser";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
  contactRequest: IContactRequest;
}

const OpenContactRequestModal: React.FC<IProps> = ({ contactRequest }) => {
  const [isCanceling, setCanceling] = useState(false);

  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const { user, canceling, cancelOk, cancelError } = useSelector((appState: IAppState) => ({
    user: appState.auth.user,
    canceling: appState.contactRequest.canceling,
    cancelOk: appState.contactRequest.cancelOk,
    cancelError: appState.contactRequest.cancelError
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if(cancelOk) {
      closeModal();
      dispatch(actions.setUser({ ...(user as IUser), openContactRequest: undefined }))
    }
    return () => {
      dispatch(actions.cancelContactRequestClear());
    }
  }, [cancelOk, closeModal, dispatch, user]);

  const cancelHandler = () => {
    dispatch(actions.cancelContactRequest(contactRequest.id));
  };

  return isCanceling ? (
    <>
      <ModalBody>
        {cancelError && <Alert>{cancelError}</Alert> } 
        {t(ETranslation.OPEN_CONTACT_MODAL_ARE_YOU_SURE)}
      </ModalBody>
      <ModalFooter>
        <Button onClick={cancelHandler} color={EButtonColor.DANGER} loading={canceling}>
        {t(ETranslation.OPEN_CONTACT_MODAL_YES)}
        </Button>
        <Button
          onClick={() => setCanceling(false)}
          color={EButtonColor.DEFAULT}
        >
          {t(ETranslation.OPEN_CONTACT_MODAL_NO)}
        </Button>
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <div>
        {t(ETranslation.OPEN_CONTACT_MODAL_SUBJECT)}{" "}
          <b>
            {getInfoContactRequestSubjectString(
              contactRequest.subject,
              contactRequest.subjectOther
            )}
          </b>
        </div>
        <p>{t(ETranslation.OPEN_CONTACT_MODAL_RESPOND)}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} color={EButtonColor.DEFAULT}>
        {t(ETranslation.OPEN_CONTACT_MODAL_CLOSE)}
        </Button>
        <Button onClick={() => setCanceling(true)} color={EButtonColor.DANGER}>
        {t(ETranslation.OPEN_CONTACT_MODAL_CANCEL)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default OpenContactRequestModal;
