import EActionTypes from '../../interfaces/store/EActionTypes';
import IContactRequestAction from '../../interfaces/store/IContactRequestAction';
import IContactRequestState from '../../interfaces/store/IContactRequestState';

type ReducerAction = IContactRequestAction;
type ReducerState = IContactRequestState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  saveOrUpdateContactRequest: null,
  saveOrUpdateLoading: false,
  saveOrUpdateError: null,
  
  canceling: false,
  cancelError: null,
  cancelOk: false

};

const saveOrUpdateContactRequestStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateError: null, saveOrUpdateLoading: true, saveOrUpdateContactRequest: null };
};

const saveOrUpdateContactRequestSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateContactRequest: action.contactRequest || null,
    saveOrUpdateError: null,
    saveOrUpdateLoading: false,
  };
};

const saveOrUpdateContactRequestFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateContactRequest: null, saveOrUpdateLoading: false, saveOrUpdateError: action.error || "Virhe! Tallennus epäonnistui." };
};

const saveOrUpdateContactRequestClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateContactRequest: null, saveOrUpdateLoading: false, saveOrUpdateError: null};
};

const cancelContactRequestStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, cancelError: null, canceling: true, cancelOk: false };
};

const cancelContactRequestSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    cancelOk: true,
    cancelError: null,
    canceling: false,
  };
};

const cancelContactRequestFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, cancelOk: false, canceling: false, cancelError: action.error || "Virhe! Tallennus epäonnistui." };
};

const cancelContactRequestClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, cancelOk: false, canceling: false, cancelError: null};
};


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_START:
      return saveOrUpdateContactRequestStart(state, action);
    case EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateContactRequestSuccess(state, action);
    case EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateContactRequestFail(state, action);
    case EActionTypes.CONTACT_REQUEST_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateContactRequestClear(state, action);
    case EActionTypes.CONTACT_REQUEST_CANCEL_START:
      return cancelContactRequestStart(state, action);
    case EActionTypes.CONTACT_REQUEST_CANCEL_SUCCESS:
      return cancelContactRequestSuccess(state, action);
    case EActionTypes.CONTACT_REQUEST_CANCEL_FAIL:
      return cancelContactRequestFail(state, action);
    case EActionTypes.CONTACT_REQUEST_CANCEL_CLEAR:
      return cancelContactRequestClear(state, action);
    default:
      return state;
  }
};

export default reducer;
