import EActionTypes from '../../interfaces/store/EActionTypes';
import IInfoAction from '../../interfaces/store/IInfoAction';
import IInfoState from '../../interfaces/store/IInfoState';

type ReducerAction = IInfoAction;
type ReducerState = IInfoState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  info: null,
  infoLoading: false,
  infoError: null,

  infos: null,
  infosLoading: false,
  infosError: null,

};

const getInfoStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoError: null, infoLoading: true, info: null };
};

const getInfoSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    info: action.info || null,
    infoError: null,
    infoLoading: false,
  };
};

const getInfoFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, info: null, infoLoading: false, infoError: action.error || "Virhe! Haku epäonnistui." };
};

const getInfoClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, info: null, infoLoading: false, infoError: null};
};

const findInfosStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infosError: null, infosLoading: true, infos: null };
};

const findInfosSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    infos: action.infos || null,
    infosError: null,
    infosLoading: false,
  };
};

const findInfosFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infos: null, infosLoading: false, infosError: action.error || "Virhe! Haku epäonnistui." };
};

const findInfosClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infos: null, infosLoading: false, infosError: null};
};



const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    
    case EActionTypes.INFO_GET_START:
      return getInfoStart(state, action);
    case EActionTypes.INFO_GET_SUCCESS:
      return getInfoSuccess(state, action);
    case EActionTypes.INFO_GET_FAIL:
      return getInfoFail(state, action);
    case EActionTypes.INFO_GET_CLEAR:
      return getInfoClear(state, action);
    case EActionTypes.INFO_FIND_START:
      return findInfosStart(state, action);
    case EActionTypes.INFO_FIND_SUCCESS:
      return findInfosSuccess(state, action);
    case EActionTypes.INFO_FIND_FAIL:
      return findInfosFail(state, action);
    case EActionTypes.INFO_FIND_CLEAR:
      return findInfosClear(state, action);
    default:
      return state;
  }
};

export default reducer;
