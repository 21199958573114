import { IOption } from '../components/ui/SimpleInput/SimpleInput';
import settings from '../customer-settings/settings';
import { ETranslation } from '../translations/translation-keys';
import { EContactRequestSubject, EContactRequestStatus } from './../interfaces/IContactRequest';



export const INFO_CONTACT_REQUEST_MUNICIPALITIES: IOption[] = settings.cities.map(city => ({ value: city }));

export const INFO_CONTACT_REQUEST_SUBJECTS: IOption[] = [
  { value: EContactRequestSubject.IMMIGRATION, labelTranslation: (ETranslation.DATA_IMMIGRATION) },
  { value: EContactRequestSubject.PERMIT_MIGRI, labelTranslation: (ETranslation.DATA_PERMIT) },
  { value: EContactRequestSubject.CLARIFICATION_AND_FORMS, labelTranslation: (ETranslation.DATA_DECISIONS) },
  { value: EContactRequestSubject.STUDYING, labelTranslation: (ETranslation.DATA_STUDYING) },
  { value: EContactRequestSubject.WORK, labelTranslation: (ETranslation.DATA_WORK) },
  { value: EContactRequestSubject.OTHER, labelTranslation: (ETranslation.DATA_OTHER) },
];

export const getInfoContactRequestSubjectString = (subject: EContactRequestSubject, subjectOther?: string ): string => {
  if(subject === EContactRequestSubject.OTHER) return subjectOther || "";
  return INFO_CONTACT_REQUEST_SUBJECTS.find(item => item.value === subject)?.label || "";
}


export const INFO_CONTACT_REQUEST_STATUSES: IOption[] = [
  { value: EContactRequestStatus.NEW, labelTranslation: (ETranslation.DATA_NEW)},
  { value: EContactRequestStatus.IN_PROGRESS, labelTranslation: (ETranslation.DATA_IN_PROGRESS)},
  { value: EContactRequestStatus.COMPLETED, labelTranslation: (ETranslation.DATA_COMPLETE) }
]


export const getInfoContactRequestStatusString = (
  status: EContactRequestStatus
): string => INFO_CONTACT_REQUEST_STATUSES.find(item => item.value === status)?.label || "";