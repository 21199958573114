export const languages = [
  { id: 'fi', name: 'FIN' },
  { id: 'en', name: 'ENG' },
  { id: 'ru', name: 'RUS' },
  { id: 'ar', name: 'ARA' },
  { id: 'sv', name: 'SWE' },
  { id: 'fas', name: 'PER' },
  { id: 'ti', name: 'TIR' },
  { id: 'kmr', name: 'KMR' },
  { id: 'th', name: 'THA' },
  { id: 'es', name: 'SPA' },
  { id: 'et', name: 'EST' },
  { id: 'uk', name: 'UKR' },
  { id: 'tr', name: 'TUR' },
]