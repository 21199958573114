import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

import classes from './SimpleInput.module.scss';

export enum EInputType {
  text = "text",
  select = "select",
}

export interface IOption {
  value: string | number;
  label?: string;
  labelTranslation?: ETranslation;
}

interface IProps {
  label?: string;
  labelTranslation?: ETranslation;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  valueTranslation?: ETranslation;
  type: EInputType;
  options?: IOption[];
  placeholder?: string;
  placeholderTranslation?: ETranslation;
  info?: string;
}

const SimpleInput: React.FC<IProps> = ({
  label,
  labelTranslation,
  onChange,
  value,
  type,
  options,
  placeholder,
  placeholderTranslation,
  info
}) => {
  const { t } = useTranslation();

  label = labelTranslation ? t(labelTranslation) : label;
  placeholder = placeholderTranslation ? t(placeholderTranslation) : placeholder;

  const getLabel = useCallback((label?: string) => {
    if (!label) return null;

    return (
      <label className={classes.Label}>
        {label} {<>&nbsp;</>}
      </label>
    );
  }, []);

  const getPlaceholder = useCallback((useLabel: boolean = true) => {
    if (placeholder) return placeholder;
    if (useLabel) {
      if (label) return label;
    }
    return "";
  }, [placeholder, label]);
  
  return (
    <div className={classes.Container}>
      {getLabel(label)}
      {info && <p style={{ margin: '0 0 .25rem 0'}}><i>{info}</i></p>}
      {(() => {
        switch (type) {
          case EInputType.text:
            return (
              <input
                className={classes.Input}
                type={type}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder={getPlaceholder()}
              />
            );
          case EInputType.select:
            return (
              <select
                className={classes.Input}
                value={value}
                onChange={(event) => onChange(event.target.value)}
              >
                {placeholder && <option value="">{placeholder}</option>}
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>{option.labelTranslation ? t(option.labelTranslation) : option.label || option.value}</option>
                ))}
              </select>
            );
        }
      })()}
    </div>
  );
};

export default SimpleInput;
