
export enum ERoutes {
  INFO_LIST = '/infos',
  INFO = '/infos/:id'
}

interface IParams {
  [key: string]: string
}

export class Routes {

  public static withParams(route: ERoutes, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key]);
    }
    return routeWithParams;
  }
}