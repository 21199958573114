enum EActionTypes {
  // InfoActions START
  INFO_GET_START,
  INFO_GET_SUCCESS,
  INFO_GET_FAIL,
  INFO_GET_CLEAR,

  INFO_FIND_START,
  INFO_FIND_SUCCESS,
  INFO_FIND_FAIL,
  INFO_FIND_CLEAR,
  // InfoActions END

  // AuthActions START
  AUTH_SET_USER,
  // AuthActions END

  // ContactRequestActions START
  CONTACT_REQUEST_SAVE_OR_UPDATE_START,
  CONTACT_REQUEST_SAVE_OR_UPDATE_SUCCESS,
  CONTACT_REQUEST_SAVE_OR_UPDATE_FAIL,
  CONTACT_REQUEST_SAVE_OR_UPDATE_CLEAR,

  CONTACT_REQUEST_CANCEL_START,
  CONTACT_REQUEST_CANCEL_SUCCESS,
  CONTACT_REQUEST_CANCEL_FAIL,
  CONTACT_REQUEST_CANCEL_CLEAR,
  // ContactRequestActions END

  // LanguageActions START
  LANGUAGE_FIND_START,
  LANGUAGE_FIND_SUCCESS,
  LANGUAGE_FIND_FAIL,
  LANGUAGE_FIND_CLEAR,
  // LanguageActions END
}

export default EActionTypes;
